
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ExercisesInMonth from "./widgets/ExercisesInMonth.vue";
import JwtService from "@/core/services/JwtService";

interface Subject {
  _id: string;
  name: string;
}

interface Exercise {
  icon: {
    file: string;
    color: string;
  };
  _id: string;
  title: string;
  createdAt: string;
  state: {
    text: string;
    color: string;
  };
  subject: Subject;
  classrooms: string[];
  module: string;
  status: string;
  reviewDate: string;
  reviewNote: string;
  type: string;
}

export default defineComponent({
  name: "teacher-exercises",
  components: { ExercisesInMonth },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const user = store.getters.currentUser;

    const exercises = ref<Exercise[]>([]);
    const filteredExercises = ref<Exercise[]>([]);

    const search = ref<string>("");
    const selectedClass = ref<string>("");

    const subjects = ref<Subject[]>([]);
    const selectedSubject = ref<string>("");
    const loading = ref(true);
    const validateStat = ref<number>(0);

    ApiService.get("/lms/homework/list")
      .then(({ data }) => {
        data.forEach((exercise) => {
          exercises.value.push({
            icon: {
              file: "media/icons/duotune/art/art005.svg",
              color: "warning",
            },
            _id: exercise._id,
            title: exercise.title,
            createdAt: exercise.createdAt,
            state: {
              text: exercise.deadline ? exercise.deadline : "-",
              color: "light-warning",
            },
            subject: exercise.subject,
            classrooms: exercise.classrooms,
            module: exercise.module,
            status: exercise.status || "notValidated",
            reviewDate: exercise.reviewDate || exercise.updatedAt,
            reviewNote: exercise.reviewNote,
            type: "exercise",
          });

          if (
            !subjects.value.find((s: Subject) => s._id == exercise.subject._id)
          )
            subjects.value.push(exercise.subject);
        });

        ApiService.get("/lms/quiz/list")
          .then(({ data }) => {
            data.forEach((exercise) => {
              exercises.value.push({
                icon: {
                  file: "media/icons/duotune/art/art002.svg",
                  color: "info",
                },
                _id: exercise._id,
                title: exercise.title,
                createdAt: exercise.createdAt,
                state: {
                  text: exercise.deadline ? exercise.deadline : "-",
                  color: "light-warning",
                },
                subject: exercise.subject,
                classrooms: exercise.classrooms,
                module: exercise.module,
                status: exercise.status || "notValidated",
                reviewDate: exercise.reviewDate,
                reviewNote: exercise.reviewNote,
                type: "quiz",
              });

              if (
                !subjects.value.find(
                  (s: Subject) => s._id == exercise.subject._id
                )
              )
                subjects.value.push(exercise.subject);
            });
            exercises.value = exercises.value.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );

            filteredExercises.value = [...exercises.value];
          })
          .catch((e) => console.log(e))
          .finally(() => {
            loading.value = false;
          });
      })
      .catch((e) => console.log(e));

    const classroomsData = ref<{ classRoom: { _id: string; name: string } }[]>(
      []
    );
    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: user._id,
        trimester: trimester,
      },
    })
      .then(({ data }) => {
        classroomsData.value = data;
      })
      .catch((e) => console.log(e));

    const filterExercises = () => {
      currentPage.value = 1;
      filteredExercises.value = exercises.value.filter((exercise) => {
        const toSearch = search.value.toLowerCase();
        return (
          exercise.title.toLowerCase().includes(toSearch) &&
          (selectedClass.value == "" ||
            exercise.classrooms.find((id) => id == selectedClass.value)) &&
          (exercise.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          ((exercise.status === "validated" && validateStat.value == 1) ||
            (exercise.status === "notValidated" && validateStat.value == 2) ||
            (exercise.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
        );
      });
    };

    const getClassroomExercises = (id: string) => {
      return exercises.value.filter((exercise: Exercise) =>
        exercise.classrooms.includes(id)
      ).length;
    };

    onMounted(() => {
      setCurrentPageTitle(t("course.myExercises"));
    });

    const getClassroomName = (classID: string): string => {
      return classroomsData.value.find((item) => classID == item.classRoom._id)
        ?.classRoom.name as string;
    };

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredExercises.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredExercises.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      exercises,
      search,
      moment,
      filterExercises,
      filteredExercises,
      classroomsData,
      selectedClass,
      getClassroomExercises,
      getClassroomName,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      validateStat,
    };
  },
});
